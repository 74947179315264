import { useCallback, useContext, useEffect, useState } from "react"
import { formatRelative } from "date-fns";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Button from '@mui/material/Button';

import { getDigiPortVesselData } from "../api/API"
import { DigiPortPilotageDataContext } from "../contexts/DigiPortPilotageDataContext"
import useToken from "../contexts/useToken"
import { getHumanReadableDateFromDigiPortDataSource } from "../lib/helper"
import { DigiPortPilotageDataEntry } from "../lib/Interfaces/DigiPortPilotageData"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

const VesselInfoText: React.FC<{
    children?: React.ReactNode;
    className?: string;
    title: string;
  }> = ({ children, className, title }) => {
    return (
      <p
        className={className}
        style={{
          margin: 0,
        }}
      >
        <span className="font-bold">{title}:</span> {children}
      </p>
    );
  };

const DigiPortPilotageDataComponent = ({
  vesselName, digiportPilotageData
}: {
  vesselName: string,
  digiportPilotageData: DigiPortPilotageDataEntry
}) => {
  const {
    vessel_name,
    agent_name,
    last_port,
    posn,
    eta,
    etd,
    rep_arrival,
    rep_departure,
    callsign,
    flag,
    next_port,
    status,
    dec_departure,
    movement_status
  } = digiportPilotageData.data;

    return (
      <>
        <div className="flex flex-row">
          <div className="flex-1">
            <VesselInfoText title="Vessel Name">
              {vessel_name ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Agent Name">
              {agent_name ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Last Port">
              {last_port ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Position">
              {posn ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="ETA">
              {getHumanReadableDateFromDigiPortDataSource(eta)}
            </VesselInfoText>
            <VesselInfoText title="Declared Arrival">
              {getHumanReadableDateFromDigiPortDataSource(rep_arrival)}
            </VesselInfoText>
            <VesselInfoText title="Reported Arrival">
              {getHumanReadableDateFromDigiPortDataSource(rep_arrival)}
            </VesselInfoText>
          </div>
          <div className="flex-1">
            <VesselInfoText title="Call Sign">
              {callsign ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Flag">
              {flag ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Next Port">
              {next_port ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Status">
              {status ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="ETD">
              {etd ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Declared Departure">
              {getHumanReadableDateFromDigiPortDataSource(dec_departure)}
            </VesselInfoText>
            <VesselInfoText title="Reported Departure">
              {getHumanReadableDateFromDigiPortDataSource(rep_departure)}
            </VesselInfoText>
          </div>
        </div>
        <p />
        <p
          style={{
              marginTop: "0.5rem",
              marginBottom: "0.25rem",
          }}
          className="font-bold text-center"
        >
          Movement Status:
        </p>
        <table
          key={vesselName}
          style={{
            border: "1px solid black",
            margin: "0 auto",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                Timestamp
              </th>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                From
              </th>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                To
              </th>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                Remarks
              </th>
            </tr>
          </thead>
          {
            movement_status.map((row, idx) => {
              return (
                <>
                  <tbody>
                    <tr
                      key={idx.toString()}
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {getHumanReadableDateFromDigiPortDataSource(row.date_time)}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {row.from}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {row.to}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {row.remarks}
                      </td>
                    </tr>
                  </tbody>
                </>
              )
            })
          }
        </table>
    </>
  )
}

interface DigiPortPilotageDataProps {
  vesselName: string
}

export const DigiPortPilotageDataContainer = ({
  vesselName
}: DigiPortPilotageDataProps) => {
  const [isCostModalAlertOpen, setIsCostModalAlertOpen] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [dataToRender, setDataToRender] = useState<DigiPortPilotageDataEntry>();

  const { data, fetchData } = useContext(DigiPortPilotageDataContext)
  const { socketId } = useContext(DigiPortPilotageDataContext)
  const { token } = useToken()
  
  const fetchDataFromServer = useCallback(async() => {
    if (!socketId) {
      console.error('socketId not available in context')
      return
    }

    setIsFetching(true)
    await getDigiPortVesselData({
      vesselName,
      socketId,
      token
    })
  }, [socketId, token, vesselName])

  useEffect(() => {
      const fetchedData = fetchData(vesselName)

      if (fetchedData) {
        setDataToRender(fetchedData)
      }
      setIsFetching(false)
      // need data from Context as a dependency
      // i want to check for most updated data to be rendered
  }, [data, fetchData, vesselName])
  
  return (
    <>
      <p
        style={{
            marginTop: "0.5rem",
            marginBottom: "0.25rem",
        }}
        className="font-bold text-center underline"
      >
        DigiPort Pilotage Data
      </p>
      <div className="flex justify-center items-center">
        <span>
          <span className="font-bold mr-1">Last fetched</span>
          {
            dataToRender?.timestamp ?
              (<>{formatRelative(new Date(dataToRender?.timestamp), new Date())}</>) : 
              (<>-</>) 
          }
        </span>
        <span>
          <IconButton
            size="small"
            disabled={isFetching}
            aria-label={isFetching ? 'loading' : 'refresh'}
            onClick={() => {
              setIsCostModalAlertOpen(!isCostModalAlertOpen)
            }}
          >
            {
              isFetching ?
                (<HourglassBottomIcon fontSize="small" />) :
                (<RefreshIcon fontSize="small" />)
            }
          </IconButton>
          <Dialog
            open={isCostModalAlertOpen}
            onClose={() => {
              setIsCostModalAlertOpen(false)
            }}
            aria-labelledby="cost-dialog-title"
            aria-describedby="cost-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to fetch pilotage data from DigiPort?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Each request costs S$0.33 and takes approximately 30 seconds to receive.
                For more information, please contact your person in-charge.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsCostModalAlertOpen(false)
                  fetchDataFromServer()
                }}
                autoFocus
              >
                Agree
              </Button>
              <Button
                onClick={() => {
                  setIsCostModalAlertOpen(false)
                }}
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      </div>
      <p />
      {
        dataToRender && (
          <DigiPortPilotageDataComponent
            digiportPilotageData={dataToRender}
            vesselName={vesselName}
          />
        )
      }
    </>
  )
}