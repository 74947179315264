import React, { useContext, useEffect, useRef, useState } from "react";
import {
  alertTimerWait,
  checkIMOFormat,
  getDecodedAccessToken,
  getFieldChangeLocation,
  getFieldChangePilotage,
  parseAlert,
  parseVesselMapObj,
  parseWebSocketPilotage,
  themeColors,
  timerIntervals,
} from "../lib/constants";
import styled from "styled-components";
import { sharedFlexCenter } from "../styles/global";
import { useMediaQuery } from "react-responsive";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import SearchIcon from "@mui/icons-material/Search";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { motion, AnimatePresence } from "framer-motion";
import VesselMapV2 from "../components/VesselMapV2";
import SideBarContent from "../components/SideBarContent";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";
import {
  addBookmark,
  getBookmarks,
  getUserType,
  getVesselLocationIMOs,
  getVesselPilotageInfo,
  pullPilotage,
  removeBookmark,
  sendPilotageUpdateEmail,
} from "../api/API";
import useToken from "../contexts/useToken";
import { useNavigate } from "react-router-dom";
import SnackbarCloseButton from "../components/SnackbarCloseButton";
import { Alert } from "../lib/Interfaces/Alert";
import { io } from "socket.io-client";
import PilotageTimeout from "../lib/Interfaces/PilotageTimeout";
import { Alarm, CalendarMonth } from "@mui/icons-material";
import AlertSideBarContent from "../components/AlertSidebarContent";
import VesselInfo from "../lib/Interfaces/VesselInfo";
import DueToArriveSideBarContent from "../components/DueToArriveSideBarContent";
import DeletionConfirmationModal from "../components/DeletionConfirmationModal";
import { WebsocketEvents } from "../lib/Interfaces/WebsocketEvents";
import { DigiPortPilotageDataContext } from "../contexts/DigiPortPilotageDataContext";
import { DigiPortPilotageData } from "../lib/Interfaces/DigiPortPilotageData";
const { v4: uuidv4 } = require("uuid");

interface VesselLocationProps {
  vessels: VesselMapObj[];
}

//Moved out so animation doesnt fire each rerender
const SideBarContainer = styled(motion.div)`
  padding: 5px;
  background-color: ${themeColors.sidebar};
  overflow-x: hidden;
`;
const Container = styled.div`
  height: 100%;
  ${sharedFlexCenter}
`;
const VesselLocation: React.FC<VesselLocationProps> = ({}) => {
  const isLargeScreen = useMediaQuery({ maxWidth: 1024 });
  const [vessels, setVessels] = useState<VesselMapObj[]>([]);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [alertFrequency, setAlertFrequency] = useState<number>(24);
  const [sid, setSid] = useState<String>("");
  const [alertActive, setAlertActive] = useState<Boolean>(false);
  const [openDeletionConfirmationModal, setOpenDeletionConfirmationModal] =
    useState<Boolean>(false);
  const [activeTimers, setActiveTimers] = useState<
    ReturnType<typeof setTimeout>[]
  >([]);
  const [pilotageTimeouts, setPilotageTimeouts] = useState<PilotageTimeout[]>(
    []
  );
  const [selectedVessel, setSelectedVessel] = useState<VesselMapObj[]>([]);
  const [sideBar, setSideBar] = useState(true);
  const [selectedSideBar, setSelectedSideBar] = useState("search");
  const [vesselPopup, setVesselPopup] = useState("");
  const [availColors, setAvailColors] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [userType, setUserType] = useState(0);
  const { token, setToken, encodedToken, setEncodedToken } = useToken();
  const navigate = useNavigate();
  const alertsRef = useRef(alerts);
  const alertActiveRef = useRef(alertActive);
  const frequencyRef = useRef(alertFrequency);
  const sidRef = useRef(sid);
  const { checkUserAccess, setSocketId, handleSetData } = useContext(DigiPortPilotageDataContext)

  //Called when page is first loaded
  useEffect(() => {
    clearAllTimeouts();
    async function fetchData() {
      //Call to get UserType here
      const userType = await getUserType(token);
      setUserType(userType.account_type);
      checkUserAccess(userType.account_type)
      setAlertFrequency(Number.parseInt(userType.notification_frequency));
      let bookmarkedVessels: any[];
      //Call get_bookmarks
      if (userType.account_type > 1) {
        bookmarkedVessels = (await getBookmarks(token)) ?? [];
      } else {
        bookmarkedVessels = [];
      }

      // If there is vessels stored in local storage
      if (
        window.localStorage.getItem("VESSELS") != null &&
        window.localStorage.getItem("COLOR") != null
      ) {
        let vessels = JSON.parse(window.localStorage.getItem("VESSELS") ?? "");
        vessels = vessels.map(parseVesselMapObj);
        let colors = JSON.parse(window.localStorage.getItem("COLOR") ?? "");
        //Compare localStorage vessels and bookmark -> Obtain a unique set of vessels
        const colorCopy = [...colors];
        let combinedVessels = consolidateVessels(
          vessels,
          bookmarkedVessels ?? []
        );
        //Assign colors to vessels
        for (const vessel of combinedVessels) {
          if (vessel.colorIndex == -1) {
            vessel.colorIndex = colorCopy.pop();
          }
        }
        setVessels(combinedVessels);
        setAvailColors(colorCopy);
        //Update local storage vessels and color
        window.localStorage.setItem("COLOR", JSON.stringify(colorCopy));
        window.localStorage.setItem("VESSELS", JSON.stringify(combinedVessels));
        //For the local storage vessels without any pilotage info, retrieve it. This means that page was reloaded when vessel was in the midst of querying pilotage information
      } else if (bookmarkedVessels.length > 0) {
        //If there is no colors set the default 10 color indexes
        let colors = window.localStorage.getItem("COLOR")
          ? JSON.parse(window.localStorage.getItem("COLOR") ?? "")
          : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const colorCopy = [...colors];
        //Assign colors to each of the bookmarked vessels
        for (const vessel of bookmarkedVessels) {
          if (vessel.colorIndex == -1) {
            vessel.colorIndex = colorCopy.pop();
          }
        }
        setSideBar(true);
        setVessels(bookmarkedVessels);
        setAvailColors(colorCopy);
        window.localStorage.setItem("COLOR", JSON.stringify(colorCopy));
        window.localStorage.setItem(
          "VESSELS",
          JSON.stringify(bookmarkedVessels)
        );
      }

      if (window.localStorage.getItem("ALERTS") != null) {
        let alerts = JSON.parse(window.localStorage.getItem("ALERTS") ?? "");
        alerts = alerts.map(parseAlert);
        alerts.forEach((alert: Alert) => {
          spawnAlertTimer(alert.timerId, alert.alarmTime);
        });
        setAlerts(alerts);
      }
    }
    fetchData();
    const socket = io("https://d3s9sj5ctusn2w.cloudfront.net", {
      transports: ["websocket", "polling"],
    });

    socket.on(WebsocketEvents.CONNECT, (sidData: {
      data?: string
    }) => {
      if (sidData?.data) {
        console.log('socket1 connected', sidData)
        setSid(sidData.data);
        setSocketId(sidData.data)
      }
    });

    socket.on(WebsocketEvents.VSIP_RECEIVED, (message: {
      data: DigiPortPilotageData
    }) => {
      handleSetData({data: message.data, timestamp: new Date().toISOString()})
      enqueueSnackbar(
        <div
          className="hover:cursor-pointer"
          onClick={() => {
            closeSnackbar();
          }}
        >
          {`DigiPort Pilotage Data received for ${message.data.vessel_name}`}
        </div>,
        {
          variant: "info",
        }
      );
    })

    socket.on(WebsocketEvents.PILOTAGE_SERVICE_RECEIVED, (data: any) => {
      const processedPilotage = parseWebSocketPilotage(data);
      setVessels((prev) => {
        const newVessels = prev.map((vessel) => {
          if (
            vessel.IMO == String(processedPilotage.allPilotage[0].pilotage_imo)
          ) {
            vessel.hasPilotageData = true;
            vessel.pilotageInfo = processedPilotage.allPilotage;
            vessel.currentPilotageInfo = processedPilotage.currentPilotage;
            enqueueSnackbar(
              <div
                className="hover:cursor-pointer"
                onClick={() => {
                  handleNavigateToVessel(vessel);
                  closeSnackbar();
                }}
              >
                {`Pilotage Data received for ${vessel.name} (${vessel.IMO})`}
              </div>,
              {
                variant: "info",
              }
            );
            removePilotageTimeout(vessel.IMO);
          }
          return vessel;
        });
        //Check if the imo is in an active alert. If it is in an active alert (save alert active state) ->
        //Update the stale information and do the pilotage check. Have to make sure that the stale info is not yet updated
        if (alertActiveRef.current) {
          setAlerts((prev) => {
            const newAlerts = prev.map((alert) => {
              if (
                alert.updated.find(
                  (updatedInfo) =>
                    updatedInfo.pilotage_nm ==
                    processedPilotage.allPilotage[0].pilotage_nm
                )
              ) {
                return alert;
              }

              //Update information reporting
              if (processedPilotage.currentPilotage) {
                //Compare to get field change.
                const fieldChanges = getFieldChangePilotage(
                  alert.stale.find(
                    (v) =>
                      v.IMO ==
                      String(processedPilotage.allPilotage[0].pilotage_imo)
                  ),
                  processedPilotage.currentPilotage
                );
                alert.updated = alert.updated.map((ir) => {
                  if (
                    ir.pilotage_imo ==
                    String(processedPilotage.allPilotage[0].pilotage_imo)
                  ) {
                    ir.pilotage_cst_dt_time =
                      processedPilotage.currentPilotage.pilotage_cst_dt_time;
                    ir.pilotage_arrival_dt_time =
                      processedPilotage.currentPilotage.pilotage_arrival_dt_time;
                    ir.pilotage_onboard_dt_time =
                      processedPilotage.currentPilotage.pilotage_onboard_dt_time;
                    ir.pilotage_start_dt_time =
                      processedPilotage.currentPilotage.pilotage_start_dt_time;
                    ir.pilotage_end_dt_time =
                      processedPilotage.currentPilotage.pilotage_end_dt_time;
                    ir.pilotage_nm =
                      processedPilotage.currentPilotage.pilotage_nm;
                    ir.pilotage_loc_to_code =
                      processedPilotage.currentPilotage.pilotage_loc_to_code;
                    ir.pilotage_loc_from_code =
                      processedPilotage.currentPilotage.pilotage_loc_from_code;
                  }
                  return ir;
                });
                alert.fieldChange = alert.fieldChange.map((fc) => {
                  if (
                    fc.imo ==
                    String(processedPilotage.allPilotage[0].pilotage_imo)
                  ) {
                    fc.fieldChange = [...fc.fieldChange, ...fieldChanges];
                  }
                  return fc;
                });
              }

              alert.pilotageCheck = [
                ...alert.pilotageCheck,
                String(processedPilotage.allPilotage[0].pilotage_imo),
              ];
              if (alert.pilotageCheck.length == alert.stale.length) {
                setAlertActive(false);
                //Perform check to see if the any field change. If there is field change send email.
                let sendEmail = false;
                alert.fieldChange.forEach((fc) => {
                  if (fc.fieldChange.length > 0) {
                    sendEmail = true;
                  }
                });
                //Field change detected. Need to update stale info
                if (sendEmail) {
                  sendPilotageUpdateEmail(alert, token);
                  alert.stale = alert.stale.map((vessel) => {
                    return {
                      ...(newVessels.find((x) => x.IMO == vessel.IMO) ??
                        vessel),
                    };
                  });
                }
                //Recreate Timer here. Update the stale data
                spawnAlertTimer(
                  alert.timerId,
                  new Date(
                    alert.alarmTime.getTime() + frequencyRef.current * 60000
                  )
                );
                alert.alarmTime = new Date(
                  alert.alarmTime.getTime() + frequencyRef.current * 60000
                );
                alert.pilotageCheck = [];
                alert.fieldChange = alert.stale.map((vessel) => {
                  return {
                    imo: vessel.IMO,
                    name: vessel.name,
                    fieldChange: [],
                  };
                });
                alert.updated = alert.stale.map((vessel) => {
                  return {
                    pilotage_imo: vessel.IMO,
                  };
                });
              }
              return alert;
            });
            window.localStorage.setItem("ALERTS", JSON.stringify(newAlerts));
            return newAlerts;
          });
        }
        window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
        return newVessels;
      });
    });
  }, []);

  useEffect(() => {
    alertsRef.current = alerts;
  }, [alerts]);

  useEffect(() => {
    frequencyRef.current = alertFrequency;
  }, [alertFrequency]);

  useEffect(() => {
    sidRef.current = sid;
  }, [sid]);

  useEffect(() => {
    alertActiveRef.current = alertActive;
  }, [alertActive]);

  function clearAllTimeouts() {
    activeTimers?.forEach((activeTimer) => {
      clearTimeout(activeTimer);
    });
    setActiveTimers([]);
    setAlerts([]);
    window.localStorage.removeItem("ALERTS");
  }

  function addTimeout(timeout: ReturnType<typeof setTimeout>) {
    setActiveTimers((prev) => {
      return [...prev, timeout];
    });
  }

  function addPilotageTimeout(
    imo: string,
    timeout: ReturnType<typeof setTimeout>
  ) {
    setPilotageTimeouts((prev) => {
      return [...prev, { imo, timeout }];
    });
  }

  function removePilotageTimeout(imo: string) {
    setPilotageTimeouts((prev) => {
      prev.map((pto) => {
        if (pto.imo == imo) {
          clearTimeout(pto.timeout);
        }
      });
      return prev.filter((pto) => pto.imo != imo);
    });
  }

  function removeTimeout(timeout: ReturnType<typeof setTimeout>) {
    setActiveTimers((prev) => {
      return prev.filter((to) => to !== timeout);
    });
  }

  function consolidateVessels(
    list1: VesselMapObj[],
    list2: VesselMapObj[]
  ): VesselMapObj[] {
    // Create a Set to store unique IMOs
    const uniqueIMOs = new Set<string>();

    // Create a result array
    const consolidatedVessels: VesselMapObj[] = [];

    // Process the first list
    for (const vessel of list1) {
      const { IMO } = vessel;
      if (!uniqueIMOs.has(IMO)) {
        uniqueIMOs.add(IMO);
        consolidatedVessels.push(vessel);
      }
    }

    // Process the second list
    for (const vessel of list2) {
      const { IMO } = vessel;
      if (!uniqueIMOs.has(IMO)) {
        uniqueIMOs.add(IMO);
        consolidatedVessels.push(vessel);
      } else {
        //If the vessel is already in the list, make sure that it is bookmarked
        const inTarget = list1.find((x) => x.IMO == IMO);
        const vesselIndex = consolidatedVessels.indexOf(inTarget ?? vessel);
        if (vesselIndex > -1) {
          consolidatedVessels[vesselIndex] = {
            ...consolidatedVessels[vesselIndex],
            bookmarked: true,
          };
        }
      }
    }

    return consolidatedVessels;
  }

  function pilotageTimeout(imos: string[]) {
    imos.forEach((imo) => {
      const to = setTimeout(() => {
        //Check if the vesselPilotage is in? If it not in then set the pilotage to null.
        //If active timer is on. Logic to update updated and to update pilotage info?
        //Currently double firing.
        setVessels((prev) => {
          const newVessels = prev.map((vessel) => {
            if (vessel.IMO == imo) {
              //So what if we learn that the new incoming info is not there. We just need to update the alert right. No we need to check if the imo is alr in.
              //Can we convert pilotage check to a list that appends checked imos?
              vessel.hasPilotageData = false;
              //Checks if the vessel's data is in prog
              if (!vessel.pilotageInfo) {
                enqueueSnackbar(
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => {
                      handleNavigateToVessel(vessel);
                    }}
                  >
                    {`Pilotage Data for ${vessel.name} (${vessel.IMO}) timed out`}
                  </div>,
                  {
                    variant: "error",
                  }
                );
              }
              vessel.pilotageInfo = [
                {
                  pilotage_imo: null,
                  pilotage_cst_dt_time: null,
                  pilotage_arrival_dt_time: null,
                  pilotage_onboard_dt_time: null,
                  pilotage_start_dt_time: null,
                  pilotage_end_dt_time: null,
                  pilotage_snapshot_dt: null,
                  pilotage_nm: null,
                  pilotage_loc_to_code: null,
                  pilotage_loc_from_code: null,
                },
              ];
            }

            return vessel;
          });

          if (alertActiveRef.current) {
            setAlerts((prev) => {
              const newAlerts = prev.map((alert) => {
                //Check if this imo has been checked. If it has then just skip.
                if (
                  alert.stale.find((v) => v.IMO == imo) &&
                  !alert.pilotageCheck.includes(imo)
                ) {
                  //Compare to get field change.
                  const fieldChanges = getFieldChangePilotage(
                    alert.stale.find((v) => v.IMO == String(imo)),
                    undefined
                  );
                  alert.updated = alert.updated.map((updatedVessel) => {
                    if (updatedVessel.pilotage_imo == imo) {
                      return {
                        ...updatedVessel,
                        pilotage_arrival_dt_time: null,
                        pilotage_cst_dt_time: null,
                        pilotage_end_dt_time: null,
                        pilotage_loc_from_code: null,
                        pilotage_loc_to_code: null,
                        pilotage_nm: null,
                        pilotage_onboard_dt_time: null,
                        pilotage_start_dt_time: null,
                      };
                    }
                    return updatedVessel;
                  });
                  //Update information reporting
                  alert.fieldChange = alert.fieldChange.map((fc) => {
                    if (fc.imo == String(imo)) {
                      fc.fieldChange = [...fc.fieldChange, ...fieldChanges];
                    }
                    return fc;
                  });
                  alert.pilotageCheck = [...alert.pilotageCheck, imo];
                  if (alert.pilotageCheck.length == alert.stale.length) {
                    setAlertActive(false);
                    //Perform check to see if the any field change. If there is field change send email.
                    let sendEmail = false;
                    alert.fieldChange.forEach((fc) => {
                      if (fc.fieldChange.length > 0) {
                        sendEmail = true;
                      }
                    });
                    //Field change detected. Need to update stale info
                    if (sendEmail) {
                      sendPilotageUpdateEmail(alert, token);
                      alert.stale = alert.stale.map((vessel) => {
                        return {
                          ...(newVessels.find((x) => x.IMO == vessel.IMO) ??
                            vessel),
                        };
                      });
                    }
                    //Recreate Timer here
                    spawnAlertTimer(
                      alert.timerId,
                      new Date(
                        alert.alarmTime.getTime() + alertTimerWait * 60000
                      )
                    );
                    alert.alarmTime = new Date(
                      alert.alarmTime.getTime() + alertTimerWait * 60000
                    );
                    alert.pilotageCheck = [];
                    alert.fieldChange = alert.stale.map((vessel) => {
                      return {
                        imo: vessel.IMO,
                        name: vessel.name,
                        fieldChange: [],
                      };
                    });
                    alert.updated = alert.stale.map((vessel) => {
                      return {
                        pilotage_imo: vessel.IMO,
                      };
                    });
                  }
                } else {
                  console.log("This alert has already been checked");
                }
                return alert;
              });
              window.localStorage.setItem("ALERTS", JSON.stringify(newAlerts));
              return newAlerts;
            });
          }
          return newVessels;
        });
        removeTimeout(to);
      }, 1 * 60000);
      addPilotageTimeout(imo, to);
    });
  }

  //Called when users logout
  function logout() {
    //Reset token, remove color and vessels from localstorage and navigate to login page.
    setToken(null);
    setEncodedToken("");
    window.localStorage.removeItem("ALERTS");
    window.localStorage.removeItem("COLOR");
    window.localStorage.removeItem("VESSELS");
    window.location.replace("/");
  }

  //Called when bookmark symbol is clicked on associated vessel card
  async function handleBookmark(imo: string) {
    const vesselInTarget = vessels.find((vessel) => vessel.IMO == imo);
    if (vesselInTarget) {
      if (vesselInTarget?.bookmarked) {
        const isRemoved = await removeBookmark(imo, token);
        if (isRemoved?.status == 200) {
          setVessels((prev) => {
            const newVessels = prev.map((vessel) => {
              if (vessel.IMO == imo) {
                vessel.bookmarked = false;
              }
              return vessel;
            });
            window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
            return newVessels;
          });
        } else if (isRemoved?.status == 401) {
          logout();
        } else {
          enqueueSnackbar(
            "Failed to remove " + vesselInTarget?.name + " from bookmark",
            {
              variant: "error",
            }
          );
        }
      } else {
        const isAdded = await addBookmark(vesselInTarget, token);
        if (isAdded?.status == 200) {
          setVessels((prev) => {
            const newVessels = prev.map((vessel) => {
              if (vessel.IMO == imo) {
                vessel.bookmarked = true;
              }
              return vessel;
            });
            window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
            return newVessels;
          });
        } else if (isAdded?.status == 401) {
          logout();
        } else {
          enqueueSnackbar(
            "Failed to add " + vesselInTarget?.name + " to bookmark",
            {
              variant: "error",
            }
          );
        }
      }
    }
  }

  //Called when vessel is removed from the fleet. ColorIndex is returned.
  function returnColor(index: number) {
    if (index < 0) {
      return;
    }
    setAvailColors((prev) => {
      const newColors = [...prev, index];
      window.localStorage.setItem("COLOR", JSON.stringify(newColors));
      return newColors;
    });
  }

  function handleSetAvailColors(newColorIndexes: number[]) {
    setAvailColors(newColorIndexes);
    window.localStorage.setItem("COLOR", JSON.stringify(newColorIndexes));
  }

  //Process imo to check if imo is suitable to request
  function checkVesselInFleet(imo: string) {
    if (!checkIMOFormat(imo)) {
      enqueueSnackbar("Invalid IMO provided! " + imo, {
        variant: "error",
      });
      return true;
    }

    const vesselInFleet = vessels.find((vl) => vl.IMO == imo);

    if (vesselInFleet) {
      enqueueSnackbar(
        <div
          className="hover:cursor-pointer"
          onClick={() => {
            handleNavigateToVessel(vesselInFleet);
          }}
        >
          {"Vessel already in fleet! " + vesselInFleet.name + " (" + imo + ")"}
        </div>,
        {
          variant: "error",
        }
      );
    }

    return vesselInFleet;
  }

  //Add a new imo paired with colorIndex to fleet
  async function handleAddToFleetIMO(imo: string, colorIndex: number) {
    if (vessels.length == 10) {
      enqueueSnackbar("Max number of vessels reached!", {
        variant: "error",
      });
    } else {
      const vesselMapObj: VesselMapObj = {
        id: uuidv4(),
        name: "",
        type: "",
        latitude: "",
        longitude: "",
        IMO: imo,
        MMSI: "",
        visible: true,
        course: 0,
        flag: "",
        loading: false,
        colorIndex: colorIndex,
        pilotageInfo: null,
        currentPilotageInfo: null,
        bookmarked: false,
        aliases: [],
        eta: null,
        etd: null,
        callsign: null,
        speed: 0,
        destination: null,
        timeStamp: null,
        hasPilotageData: true,
        dueToArrive: null,
        dueToDepart: null,
        locationFrom: "",
        locationTo: "",
      };
      setVessels((prev) => {
        const newVessels = [...prev, vesselMapObj];
        return newVessels;
      });
    }
  }

  async function handleAddToFleetName(vessel: VesselInfo, colorIndex: number) {
    if (vessels.length == 10) {
      enqueueSnackbar("Max number of vessels reached!", {
        variant: "error",
      });
    } else if (
      vessels.find((existingVessel) => existingVessel.IMO == vessel.vesselImo)
    ) {
      enqueueSnackbar(
        `Vessel ${vessel.vesselName} (${vessel.vesselImo}) already in fleet!`,
        {
          variant: "error",
        }
      );
    } else {
      const vesselMapObj: VesselMapObj = {
        id: uuidv4(),
        name: vessel.vesselName,
        type: vessel.vesselType,
        latitude: "",
        longitude: "",
        IMO: vessel.vesselImo,
        MMSI: vessel.vesselMMSI,
        flag: vessel.vesselFlag,
        visible: true,
        course: 0,
        aliases: [],
        loading: true,
        colorIndex: colorIndex,
        pilotageInfo: null,
        currentPilotageInfo: null,
        bookmarked: false,
        eta: null,
        etd: null,
        callsign: vessel.vesselCallsign ?? "",
        speed: 0,
        destination: null,
        timeStamp: null,
        hasPilotageData: true,
        dueToArrive: null,
        dueToDepart: null,
        locationFrom: "",
        locationTo: "",
      };
      setVessels((prev) => {
        const newVessels = [...prev, vesselMapObj];
        return newVessels;
      });
    }
  }

  //Called when updated vessel information is received. Updates vessel information
  function updateFleet(updatedVessel: VesselMapObj) {
    console.log(updatedVessel);
    setVessels((prev) => {
      const newVessels = prev.map((vessel) => {
        if (vessel.IMO == updatedVessel.IMO) {
          vessel.name = updatedVessel.name;
          vessel.course = updatedVessel.course;
          vessel.latitude = updatedVessel.latitude;
          vessel.longitude = updatedVessel.longitude;
          vessel.eta = updatedVessel.eta;
          vessel.etd = updatedVessel.etd;
          vessel.flag = vessel.flag != "" ? vessel.flag : updatedVessel.flag;
          vessel.MMSI = updatedVessel.MMSI;
          vessel.type = vessel.type != "" ? vessel.type : updatedVessel.type;
          vessel.callsign = updatedVessel.callsign;
          vessel.speed = updatedVessel.speed;
          vessel.timeStamp = updatedVessel.timeStamp;
          vessel.destination = updatedVessel.destination;
          vessel.aliases = updatedVessel.aliases;
          vessel.loading = false;
          vessel.dueToArrive = updatedVessel.dueToArrive;
          vessel.dueToDepart = updatedVessel.dueToDepart;
          vessel.locationFrom = updatedVessel.locationFrom;
          vessel.locationTo = updatedVessel.locationTo;
        }
        return vessel;
      });
      window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
      return newVessels;
    });
  }

  //Open deletion confirmation modal
  function handleRemoveVessel(vessel: VesselMapObj) {
      // Open modal
      setOpenDeletionConfirmationModal(true);
  }

  function handleSelectedVesselsRemove() {
    setVessels((prev) => {
      const newVessels = prev.filter(
        (pv) => !selectedVessel.some((sv) => sv.id === pv.id)
      );
      if (newVessels.length !== 0) {
        window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
      } else {
        window.localStorage.removeItem("VESSELS");
      }
      return newVessels;
    });

    let colorBasket: number[] = [];
    // Return colors for all removed vessels
    selectedVessel.forEach((vessel) => colorBasket.push(vessel.colorIndex));
    handleSetAvailColors([...availColors, ...colorBasket]);

    setAlerts((prev) => {
      let empty = false;
      let newAlerts = prev.map((alert) => {
        alert.stale = alert.stale.filter(
          (staleVessel) =>
            !selectedVessel.some((sv) => sv.IMO === staleVessel.IMO)
        );
        if (alert.stale.length === 0) {
          clearAllTimeouts();
          empty = true;
        }
        return alert;
      });

      if (empty) {
        newAlerts = [];
      }
      window.localStorage.setItem("ALERTS", JSON.stringify(newAlerts));
      return newAlerts;
    });

    // Notify for each removed vessel
    selectedVessel.forEach((vessel) => {
      enqueueSnackbar(`${vessel.name} removed from tracking!`, {
        variant: "error",
      });
    });

    // Clear the selection after removal
    setSelectedVessel([]);
    setOpenDeletionConfirmationModal(false);
  }

  function handleRemoveVesselIMO(vessel: string) {
    setVessels((prev) => {
      const vesselInTarget = prev.find((pv) => pv.IMO == vessel);
      returnColor(vesselInTarget?.colorIndex ?? -1);
      enqueueSnackbar(`Failed to retrieve location details for ${vessel}!`, {
        variant: "error",
      });
      const newVessels = prev.filter((pv) => pv.IMO !== vessel);
      if (newVessels.length != 0) {
        window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
      } else {
        window.localStorage.removeItem("VESSELS");
      }
      return newVessels;
    });
  }

  //Look at token field to perform validation
  function checkSearchLimit(imoListLength: number) {
    const search_limit = getDecodedAccessToken(token).sub.search_limit;
    if (imoListLength > search_limit) {
      enqueueSnackbar(
        `Search limit reached (${search_limit}). Please try again.`,
        {
          variant: "error",
        }
      );
      return true;
    }
    return false;
  }

  //Selects all vessels
  function handleSelectAllVessels() {
    //If all is selected already, then reset the selection
    if (selectedVessel.length == vessels.length) {
      setSelectedVessel([]);
    } else {
      setSelectedVessel(vessels);
    }
  }

  function handleCloseSidebarRefresh() {
    setSideBar(false);
    setForceRefresh((prev) => prev + 1);
  }

  //Called when update location is called
  async function handleRetrieveVesselLocation() {
    if (selectedVessel.length == 0) {
      enqueueSnackbar("No Vessels Selected!", {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Fetching vessels locations", {
        variant: "info",
      });
      const temp = selectedVessel;
      setSelectedVessel([]);
      //Update each vessel card to show loading status
      temp.forEach((vessel) => {
        setVessels((prev) => {
          return prev.map((pv) => {
            if (pv == vessel) {
              pv.loading = true;
            }
            return pv;
          });
        });
      });
      const tempIMO = temp.map((vessel) => vessel.IMO);
      const updatedLoc = await getVesselLocationIMOs(
        tempIMO.toString(),
        token,
        sid
      );
      //Set pilotage timeout
      pilotageTimeout(tempIMO);
      //Token is expired so log user out
      if (updatedLoc.status == 401) {
        logout();
      } else if (updatedLoc.status != 200) {
        temp.forEach((vessel) => {
          setVessels((prev) => {
            return prev.map((pv) => {
              if (pv == vessel) {
                pv.loading = false;
              }
              return pv;
            });
          });
        });
        enqueueSnackbar("Failed to retrieve vessel location!", {
          variant: "error",
        });
        return;
      }

      //Update details of vessels that have been fetched
      updatedLoc.vessels.map((vessel) => {
        setVessels((prev) => {
          const newVessels = prev.map((pv) => {
            if (pv.IMO == vessel.IMO) {
              pv.loading = false;
              pv.latitude = vessel.latitude;
              pv.longitude = vessel.longitude;
              pv.course = vessel.course;
              pv.eta = vessel.eta;
              pv.etd = vessel.etd;
              pv.MMSI = vessel.MMSI;
              pv.destination = vessel.destination;
              pv.callsign = vessel.callsign;
              pv.speed = vessel.speed;
              pv.timeStamp = vessel.timeStamp;
              pv.hasPilotageData = true;
            }
            return pv;
          });
          window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
          return newVessels;
        });
      });

      // spawnPilotageInfoTimer(tempIMO);
    }
  }

  //Called when user double clicks on the vessel name card
  function handleNavigateToVessel(vesselInfo: VesselMapObj) {
    setSelectedVessel([]);
    if (mapRef.current && !vesselInfo.loading) {
      const map = mapRef.current;

      // Calculate the offset for latitude and longitude
      const latitudeOffset = 0.01; // Adjust as needed

      // Calculate the new latitude and longitude values
      const newLatitude =
        Number.parseFloat(vesselInfo.latitude) + latitudeOffset;
      const newLongitude = Number.parseFloat(vesselInfo.longitude);

      // Set selection
      setVesselPopup(vesselInfo.IMO);

      // Fly to the new position
      map.flyTo([newLatitude, newLongitude], 15, {
        duration: 1,
        animate: true,
      });
    }
  }

  //Close the vessel popup
  function handleClosePopup(vesselInfo: VesselMapObj) {
    //Remove selection
    setVesselPopup("");
  }

  //Update the vessel whose marker has been clicked on
  function handleSelectedVessel(vessel: VesselMapObj) {
    if (selectedVessel.find((sv) => sv == vessel)) {
      setSelectedVessel((prev) => {
        return prev.filter((sv) => sv != vessel);
      });
    } else {
      setSelectedVessel((prev) => [...prev, vessel]);
    }
  }

  function resetSelectedVessel() {
    setSelectedVessel([]);
  }

  async function spawnAlertTimer(timerId: string, alarmTime: Date) {
    //Configure alert based on the alert params
    const currentTime = new Date();
    const fireAlert = async () => {
      // Fetch alert
      const alertInTarget = alertsRef.current.find((x) => x.timerId == timerId);

      //Trigger if the alert type is for location. Need check to see if it is only location, then it will immediately call the email function.
      if (alertInTarget?.alertType.locationAlert) {
        //Construct list of IMO to fetch vessel location
        const searchableIMO: string[] = [];
        alertInTarget.stale.forEach((vessel) => searchableIMO.push(vessel.IMO));

        //Fetch vessel location
        const vesselLocation: {
          status: number;
          vessels: any[];
        } = await getVesselLocationIMOs(searchableIMO.toString(), token, sid);
        if (vesselLocation.status == 401) {
          console.log("Token is expired");
        }
        //Loop through vessel location results
        for (const res of vesselLocation.vessels) {
          //Update current fleet information so that users can visually view the updates as well
          updateFleet(res);
          //Update information in the alerts
          alertsRef.current.map((alert) => {
            if (alert.timerId == timerId) {
              //Compare to get field change.
              const fieldChanges = getFieldChangeLocation(
                alert.stale.find((v) => v.IMO === res.IMO),
                res
              );
              alert.fieldChange = alert.fieldChange.map((fc) => {
                if (fc.imo == res.IMO) {
                  fc.fieldChange = [...fc.fieldChange, ...fieldChanges];
                }
                return fc;
              });
              alert.updated = alert.updated.map((ir) => {
                if (ir.pilotage_imo == String(res.IMO)) {
                  ir.eta = res.eta;
                  ir.etd = res.etd;
                  ir.destination = res.destination;
                }
                return ir;
              });
            }
            return alert;
          });
          window.localStorage.setItem(
            "ALERTS",
            JSON.stringify(alertsRef.current)
          );
          setAlerts(alertsRef.current);
        }
      }

      if (!alertInTarget?.alertType.pilotageAlert) {
        //Loc only. Check field change and send email
        //Check for any field changes
        alertsRef.current.map((alert) => {
          if (alert.timerId == timerId) {
            let sendEmail = false;
            alert.fieldChange.forEach((fc) => {
              if (fc.fieldChange.length > 0) {
                sendEmail = true;
              }
            });
            //Field change detected. Need to update stale info
            if (sendEmail) {
              sendPilotageUpdateEmail(alert, token);
              alert.stale = alert.stale.map((vessel) => {
                return {
                  ...(vessels.find((x) => x.IMO == vessel.IMO) ?? vessel),
                };
              });
            }
            //Adjust params for timer wait time
            spawnAlertTimer(
              timerId,
              new Date(alarmTime.getTime() + alertTimerWait * 60000)
            );
            alert.alarmTime = new Date(
              alert.alarmTime.getTime() + alertTimerWait * 60000
            );
            alert.pilotageCheck = [];
            alert.fieldChange = alert.stale.map((vessel) => {
              return {
                imo: vessel.IMO,
                name: vessel.name,
                fieldChange: [],
              };
            });
            alert.updated = alert.stale.map((vessel) => {
              return {
                pilotage_imo: vessel.IMO,
              };
            });
          }
          return alert;
        });
      } else {
        const imos = alertInTarget?.stale.map((v) => v.IMO);
        await pullPilotage(
          imos ? imos.toString() : "[]",
          token,
          sidRef.current
        );
        pilotageTimeout(imos);
        setAlertActive(true);
        //Call function that will create a timeout for each imo pilotage call. After specified amount of time.
        //Expire and for each of the timer it is tag with the imo ... When timeout. Check imo and update the fleet's pilotage in prog status.

        //Email check done in the timer
        //Spawn 3 timers for pilotage / location / pilotage and location
        // alertInTarget?.stale.forEach((vessel) => {
        //   //Pilotage spawner but if updates to alert information. Has checks to see if all is done. If all is done then fire email.
        //   pilotageFindAlert(vessel.IMO, 0, timerId);
        // });
        // THIS PORTION REPLACED WITH TIMER. SHOULD HAVE TIMER LOGIC
      }
    };

    const to = setTimeout(async () => {
      await fireAlert();
      removeTimeout(to);
    }, alarmTime.getTime() - currentTime.getTime());
    addTimeout(to);
  }

  function addAlert(newAlert: Alert) {
    const currentTime = new Date();
    clearAllTimeouts();
    setAlerts(() => {
      const newAlerts = [newAlert];
      window.localStorage.setItem("ALERTS", JSON.stringify(newAlerts));
      return newAlerts;
    });
  }

  const mapRef = useRef<L.Map>(null);

  return (
    <div className="overflow-hidden flex flex-row">
      <Container
        className={`relative overflow-hidden ${
          isLargeScreen && sideBar ? "w-0" : "w-full"
        };`}
      >
        <VesselMapV2
          mapRef={mapRef}
          isLargeScreen={isLargeScreen}
          sideBar={sideBar}
          vessels={vessels}
          forceRefresh={forceRefresh}
          vesselPopup={vesselPopup}
          handleClosePopup={handleClosePopup}
          userType={userType}
        />
        <div className="absolute right-0 top-0 z-30">
          <button
            className="p-1"
            style={{
              backgroundColor: themeColors.sidebar,
            }}
            onClick={() => {
              if (isLargeScreen) {
                setForceRefresh((prev) => prev + 1);
              }
              setSelectedSideBar("search");
              setSideBar((prev) => {
                if (prev && selectedSideBar === "search") {
                  return false;
                } else {
                  return true;
                }
              });
            }}
          >
            {sideBar && selectedSideBar === "search" ? (
              <ArrowRightIcon htmlColor="white" fontSize="medium" />
            ) : (
              <SearchIcon htmlColor="white" />
            )}
          </button>
        </div>
        <div className="absolute right-0 top-10 z-30">
          <button
            className="p-1"
            style={{
              backgroundColor: themeColors.sidebar,
            }}
            onClick={() => {
              setSelectedSideBar("arrival");
              setSideBar((prev) => {
                if (prev && selectedSideBar === "arrival") {
                  return false;
                } else {
                  return true;
                }
              });
            }}
          >
            {sideBar && selectedSideBar === "arrival" ? (
              <ArrowRightIcon htmlColor="white" fontSize="medium" />
            ) : (
              <CalendarMonth htmlColor="white" />
            )}
          </button>
        </div>
        <div className="absolute right-0 top-20 z-30">
          <button
            className="p-1"
            style={{
              backgroundColor: themeColors.sidebar,
            }}
            onClick={() => {
              setSelectedSideBar("alarm");
              setSideBar((prev) => {
                if (prev && selectedSideBar === "alarm") {
                  return false;
                } else {
                  return true;
                }
              });
            }}
          >
            {sideBar && selectedSideBar == "alarm" ? (
              <ArrowRightIcon htmlColor="white" fontSize="medium" />
            ) : (
              <Alarm htmlColor="white" />
            )}
          </button>
        </div>
      </Container>
      <AnimatePresence>
        {sideBar && (
          <SideBarContainer
            initial={{ width: 0, height: "screen" }}
            animate={{
              width: isLargeScreen
                ? "100vw"
                : selectedSideBar === "arrival"
                ? "60%"
                : "40%",
            }}
            exit={{ width: 0 }}
            transition={{ duration: isLargeScreen ? "0" : "0.25" }}
            style={{
              minWidth: isLargeScreen ? "320px" : "0px",
            }}
          >
            <div style={{ height: "calc(100vh - 10px)", overflowY: "auto" }}>
              {selectedSideBar === "search" && (
                <SideBarContent
                  pilotageTimeout={pilotageTimeout}
                  sid={sid}
                  handleRetrieveVesselLocation={handleRetrieveVesselLocation}
                  handleSelectAllVessels={handleSelectAllVessels}
                  handleRemoveVessel={handleRemoveVessel}
                  handleRemoveVesselIMO={handleRemoveVesselIMO}
                  handleAddToFleetIMO={handleAddToFleetIMO}
                  handleAddToFleetName={handleAddToFleetName}
                  selectedVessel={selectedVessel}
                  handleNavigateToVessel={handleNavigateToVessel}
                  handleSelectedVessel={handleSelectedVessel}
                  vessels={vessels}
                  handleCloseSidebar={handleCloseSidebarRefresh}
                  handleSetAvailColors={handleSetAvailColors}
                  checkVesselInFleet={checkVesselInFleet}
                  updateFleet={updateFleet}
                  availColors={availColors}
                  isLargeScreen={isLargeScreen}
                  handleLogout={logout}
                  handleBookmark={handleBookmark}
                  logout={logout}
                  checkSearchLimit={checkSearchLimit}
                  spawnAlertTimer={spawnAlertTimer}
                  addAlert={addAlert}
                  resetSelectedVessel={resetSelectedVessel}
                  userType={userType}
                />
              )}
              {selectedSideBar == "alarm" && (
                <AlertSideBarContent
                  alert={alerts[0]}
                  clearAlert={clearAllTimeouts}
                  handleLogout={logout}
                  isLargeScreen={isLargeScreen}
                  handleCloseSidebar={handleCloseSidebarRefresh}
                  logout={logout}
                />
              )}
              {selectedSideBar == "arrival" && (
                <DueToArriveSideBarContent
                  handleLogout={logout}
                  isLargeScreen={isLargeScreen}
                  handleCloseSidebar={handleCloseSidebarRefresh}
                  logout={logout}
                />
              )}
            </div>
          </SideBarContainer>
        )}
      </AnimatePresence>
      <SnackbarProvider
        action={(snackbarKey) => (
          <SnackbarCloseButton snackbarKey={snackbarKey} />
        )}
        maxSnack={10}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
      />
      {openDeletionConfirmationModal && (
        <DeletionConfirmationModal
          handleMultipleVesselRemove={handleSelectedVesselsRemove}
          handleConfirmationModalClose={() => {
            setOpenDeletionConfirmationModal(false);
          }}
          selectedVessels={selectedVessel}
        />
      )}
    </div>
  );
};

export default VesselLocation;
