import axios, { AxiosError } from 'axios';
import qs from 'qs'
import { convertToNotificationEmail, convertToVesselDueToArrive,  convertToInfoReporting, convertToPilotageInfo, convertToVesselMapObj, convertVesselSearchResultToVesselInfo } from '../lib/constants';
import VesselMapObj from '../lib/Interfaces/VesselMapObj';
import { Alert } from '../lib/Interfaces/Alert';
import { InfoReporting } from '../lib/Interfaces/InfoReporting';
import { FieldChange } from '../lib/Interfaces/FieldChange';
const { v4: uuidv4 } = require('uuid');

const scraperAPI = "http://localhost:5000/"
const API = process.env.REACT_APP_BE_ENDPOINT_DEP
export async function getVessels(vesselName: String, searchTag: number) {
    try {
        const response = await axios.get(scraperAPI + "getVesselsSX/" + vesselName);

        if (response.data && response.data.error) {
            throw new Error(response.data.error);
        }

        return response.data.map((vessel: any) => ({
            vesselName: vessel.NAME,
            vesselImo: vessel.IMO ?? "",
            vesselType: vessel.TYPE ?? "",
            vesselMMSI: vessel.MMSI ?? "",
            searchTag
        }));
    } catch (error: any) {
        console.error('Error fetching vessels:', error.message);
        // You can throw the error again if needed or handle it appropriately
        throw error;
    }
}
export async function getVesselLocationMMSI(MMSI: String) {
    const response = await axios.get(scraperAPI + 'getLastPosition/' + MMSI);

    if (response.data.err) {
        return {
            timestamp: null,
            latitude: null,
            longitude: null,
            course: null,
        };
    }
    const vesselLocation = {
        timestamp: response.data.data.timestamp,
        latitude: response.data.data.latitude,
        longitude: response.data.data.longitude,
        course: response.data.data.course,
    };

    return vesselLocation;
}
export async function getVesselLocationIMOs(IMOs: String, token: String, sid: String) {
    const data = {
        vessel_imo: IMOs,
        lbo_imei: '',
        form_sid: sid
    }

    try {
        const response = await axios.post(API + '/api/vessel_map', qs.stringify(data), {
            headers: {
                "Authorization": 'Bearer ' + token,
            }
        });
        if (response.status != 200) {
            return {
                status: response.status,
                vessels: []
            }
        }
        let vesselsData = []
        console.log("RECEIVING THIS")
        console.log(response.data)
        for (const res of response.data) {
            vesselsData.push(convertToVesselMapObj(res))
        }
        return {
            status: 200,
            vessels: vesselsData
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            vessels: []
        }
    }

}

export async function pullPilotage(IMOs: String, token: String, sid: String) {
    const data = {
        vessel_imo: IMOs,
        lbo_imei: '',
        form_sid: sid
    }

    try {
        const response = await axios.post(API + '/api/pilotage_pull', qs.stringify(data), {
            headers: {
                "Authorization": 'Bearer ' + token,
            }
        });
        if (response.status != 200) {
            return {
                status: response.status,
                msg: "Error"
            }
        }

        return {
            status: 200,
            msg: response.data
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            msg: ""
        }
    }

}

export async function getBookmarks(token: String) {
    try {
        const response = await axios.post(API + '/get_bookmarks', {}, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            }
        });
        let vesselsData = []
        for (const res of response.data.vessel_data) {
            vesselsData.push({
                id: uuidv4(),
                name: res.vessel_name,
                type: res.vessel_type,
                latitude: "",
                longitude: "",
                IMO: res.vessel_IMO,
                MMSI: res.vessel_MMSI,
                flag: res.vessel_flag,
                visible: true,
                course: 0,
                loading: false,
                colorIndex: -1,
                pilotageInfo: null,
                currentPilotageInfo: null,
                bookmarked: true,
                eta: null,
                etd: null,
                callsign: res.vessel_callsign,
                speed: 0,
                destination: null,
                timeStamp: null,
                popupOpen: false,
                hasPilotageData: true,
                aliases: res.vessel_alias ? res.vessel_alias.split(','): []
            })
        }
        return vesselsData
    } catch (e) {
        console.log(e)
    }
}

export async function removeBookmark(imo: String, token: String) {
    try {
        const response = await axios.post(API + '/delete_vessel_from_bookmark',
            { vessel_IMO: imo },
            {
                headers: {
                    "Authorization": 'Bearer ' + token,
                    "Content-Type": "application/json",
                }
            });

        return response
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            vessels: []
        }
    }
}

export async function addBookmark(vessel: VesselMapObj, token: String) {
    try {
        const response = await axios.post(API + '/add_vessel',
            {
                vessel_data: {
                    vessel_name: vessel.name,
                    vessel_MMSI: vessel.MMSI ?? "",
                    vessel_IMO: vessel.IMO,
                    vessel_callsign: vessel.callsign,
                    vessel_type: vessel.type,
                    vessel_flag: vessel.flag,
                    vessel_alias: vessel.aliases.join(',')
                }
            },
            {
                headers: {
                    "Authorization": 'Bearer ' + token,
                    "Content-Type": "application/json",
                }
            });
        return response
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            vessels: []
        }
    }
}

export async function sendPilotageUpdateEmail(alert: Alert, token: String) {

    const dataBody: {
        old_vessel_info?: {
            vessel_imo?: string | null
            vessel_info?: any | null
        }[];
        new_vessel_info?: {
            vessel_imo?: string | null
            vessel_info?: any | null
        }[];
        updated_fields?: {
            vessel_imo?: string | null
            vessel_nm?: string | null
            updated_field: string[]
        }[]
    } = {
        old_vessel_info: alert.stale.map((vesselInfo) => {
            const infoReporting = convertToInfoReporting(vesselInfo)
            return {
                vessel_imo: vesselInfo.IMO,
                vessel_info: convertToNotificationEmail(infoReporting)
            }
        }),
        new_vessel_info: alert.updated.map((vesselInfo) => {
            return {
                vessel_imo: vesselInfo.pilotage_imo,
                vessel_info: convertToNotificationEmail(vesselInfo)
            }
        }),
        updated_fields: alert.fieldChange.map((fc) => {
            return {
                vessel_imo: fc.imo,
                vessel_nm: fc.name,
                updated_field: fc.fieldChange
            }
        })
    }
    console.log("ATTEMPTING TO SEND THIS TO NOTIFY")
    console.log(dataBody)
    try {
        const response = await axios.post(API + '/api/notify', dataBody, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            }
        });
        console.log("Response Data")
        console.log(response.data)
        console.log("RECEIVED PILOTAGE")
        return
    } catch (e) {
        console.log(e)
    }
}

export async function getVesselPilotageInfo(IMOs: String[], token: String, sid: String) {
    const data = {
        imoList: IMOs,
        form_sid: sid
    }
    try {
        const response = await axios.post(API + '/api/check_pilotage_data', data, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            }
        });
        let vesselsData = []
        for (const res of response.data) {
            console.log(data)
            vesselsData.push(convertToPilotageInfo(res))
        }
        console.log("RECEIVED PILOTAGE")
        console.log(vesselsData)
        return vesselsData
    } catch (e) {
        console.log(e)
    }

    return []
}

export async function getVesselNameSearchResults(search: String, token: String) {

    try {

        const vesselNameSearchResults = await axios.post(API + '/search_vessel_name', {
            vessel_name: search
        },  {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            }
        })

        if (vesselNameSearchResults.status == 200) {
            return {
                status: vesselNameSearchResults.status,
                data: vesselNameSearchResults.data
            }
        } else {
            return {
                status: vesselNameSearchResults.status,
                data: []
            }
        }
    }
     catch(e) {
        return {
            status: 404,
            data: []
        }
    }
}

export async function getUserType(token: String) {
    try {
        const response = await axios.post(API + '/get_user_role', {}, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            }
        });
        
        let data = response.data

        let userRole = {
            "account_type": 0,
            "notification_frequency": data.notification_frequency
        }

        if (data.account_type == "Paid_1") {
            userRole.account_type = 1
        } else if (data.account_type == "Paid_2") {
            userRole.account_type = 2
        } else if (data.account_type == "Paid_3") {
            userRole.account_type = 3
        }

        return userRole
    } catch (e) {
        console.log(e)
    }

    return {
        "account_type": 0,
        "notification_frequency": 0
    }
}

export async function getVesselsDueToArrive(token: String) {
    const response = await axios.get(API + '/dueToArrive', {
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        }
    });
    return convertToVesselDueToArrive(response.data)
}

export async function getDigiPortVesselData({
    vesselName, socketId, token
}: {
    vesselName: string,
    socketId: string,
    token: string
}): Promise<boolean> {
    try {
        // await axios.post('http://localhost:4000/api/vessel-status-in-port/send', 
        await axios.post(API + '/api/vessel-status-in-port/send', {
            vessel_name: vesselName,
            socket_id: socketId
        }, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        })

        return true
    } catch (error) {
        console.error('Error calling POST /api/vessel-status-in-port/send', error)

        return false
    }
}